@import "../../style";

.map-section {
    position: relative;
    .close-map-btn {
        @include buttonWithArrow();
        position: absolute;
        bottom: 50px;
        left: 20px;
        z-index: 1000;
        color: #121212;
        background-color: #ffffff;
        text-transform: uppercase;
        .btn-arrow {
            background-image: url(../../assets/arrow-right-red.svg);
        }
    }
    .tablet-close-map-btn {
        z-index: 1000;
        display: none;
        @include buttonWithArrow();
        position: absolute;
        bottom: 60px;
        left: 20px;
        z-index: 1000;
        color: #121212;
        background-color: #ffffff;
        text-transform: uppercase;
        .btn-arrow {
            background-image: url(../../assets/arrow-right-red.svg);
        }
    }
    .close-map-btn.not-active {
        transition: 1s all;
        transform: translateX(-300%);
    }
    .close-map-btn.active {
        transition: 1s all;
        transform: translateX(0%);
    }
    .tablet-close-map-btn.show-map {
        transition: 1s all;
        transform: translateX(0%);
    }
    .tablet-close-map-btn.hide-map {
        transition: 1s all;
        transform: translateX(-300%);
    }
    .map-wrapper {
        display: grid;
        grid-template-columns: 0.92fr 1fr;
        height: 600px;
        background-color: #c74869;
    }
    .tablet-map-wrapper {
        display: none;
    }
    .map-content {
        background-color: #c74869;
        position: relative;
        height: 100%;
        .map-text {
            position: absolute;
            width: 530px;
            top: 85px;
            right: 70px;
            .big-header {
                color: white;
            }
            .subtitle {
                color: white;
            }
            .big-header:nth-child(2) {
                margin-bottom: 24px;
            }
            .subtitle {
                margin-bottom: 32px;
            }
            .map-btn {
                @include buttonWithArrow();
                background: #121212;
                text-transform: uppercase;
            }
        }
    }
    .map-overlap {
        filter: grayscale(100%);
        height: 100%;
        transition: 0.5s all;
        &:hover {
            filter: grayscale(0%);
        }
    }
    .map {
        width: 100%;
        height: 100%;
    }
    .not-active {
        grid-template-columns: 0.92fr 1fr;
        transition: 1s all;
    }
    .active {
        grid-template-columns: 0fr 1fr;
        transition: 1s all;
        .map-content {
            transform: translateX(-110%);
            transition: 2s all;
        }
    }
    .map-wrapper.tablet-active {
        transition: 1s all;
        .map-content {
            transition: 2s all;
            height: 0px;
            padding: 0px;
            opacity: 0;
            .map-text {
                transition: 1s all;
                height: 0px;
                padding: 0px;
                opacity: 0;
                visibility: hidden;
            }
        }
        .map-overlap {
            height: 180%;
            transform: translateY(-37%);
        }
    }
}

@media (max-width: 1300px) {
    .map-section {
        .map-content {
            height: auto;
            .map-text {
                width: 400px;
            }
        }
        .map-overlap {
            min-height: 400px;
            filter: grayscale(0%);
        }
    }
}
@media (max-width: 1020px) {
    .map-section {
        .map-content {
            .map-text {
                right: 30px;
            }
        }
    }
}

/* Tablet */
@media (max-width: 992px) {
    .map-section {
        .map-content {
            .map-text {
                top: 15px;
                right: 0px;
                width: 100%;
                padding: 35px 32px;
            }
        }
        .map-wrapper {
            grid-template-columns: 1fr;
            grid-template-rows: 0.8fr 1.2fr;
            transition: 1s all;
            height: auto;
        }
        .tablet-close-map-btn {
            display: block;
        }
        .desktop-map-wrapper {
            display: none;
        }
        .tablet-map-wrapper {
            display: grid;
            grid-template-rows: 1fr;
            overflow: hidden;
            .map-content {
                transition: 1.3s all;
            }
        }
        .tablet-map-wrapper.show-map {
            grid-template-columns: 0fr 1fr;
            .map-content {
                // transform: translateX(-200%);
                right: 200%;
                z-index: -1;
                opacity: 0;
                .map-text {
                    transition: 0.5s all;
                    opacity: 0;
                }
            }
        }
        .tablet-map-wrapper.hide-map {
            grid-template-columns: 1fr 0fr;
            .map-content {
                // transform: translateX(0%);
                z-index: 1;
                opacity: 1;
                right: 0px;
                .map-text {
                    transition: 0.5s all;
                    opacity: 1;
                }
            }
        }
        .tablet-row {
            display: flex;
            gap: 10px;
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
    .map-section {
        .tablet-map-wrapper {
            min-height: 520px;
            .map-content {
                .map-text {
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 35px 16px;
                }
            }
        }
    }
}
