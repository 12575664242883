.contacts-page {
    overflow: hidden;
    .preview-section {
        margin-bottom: 40px;
    }
    .preview-section {
        background-image: url(../../../public/images/contacts-bg.webp);
    }
    .contacts-row {
        gap: 25px;
        margin-bottom: 80px;
    }
    .contact-row-mobile {
        display: none;
    }
    .contact-item {
        width: 100%;
        text-align: center;
        height: 254px;
        padding: 35px 10px;
        .contact-img {
            width: 45px;
            height: 45px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin: 0 auto 16px auto;
        }
        .contact-item-header {
            font-family: "Roboto";
            font-weight: 800;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.06em;
            margin-bottom: 16px;
        }
        .flag-and-number {
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
        .usa-flag {
            width: 12px;
            height: 12px;
            background-image: url(../../assets/us-flag.svg);
        }
        .contact-item-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;
            span {
                font-weight: 700;
            }
        }
    }
    .contact-item-1 {
        .contact-item-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 36px;
        }
    }

    .contact-item-1 {
        background: #eceff4;
        color: #1c1c1c;
        .contact-img {
            background-image: url(../../assets/contacts-phone.svg);
        }
    }
    .contact-item-2 {
        background: #12283e;
        color: white;
        .contact-img {
            background-image: url(../../assets/contacts-mark.svg);
        }
        .contact-item-text {
            margin-bottom: 10px;
        }
    }
    .contact-item-3 {
        background: #c74869;
        color: white;
        .contact-img {
            background-image: url(../../assets/contacts-mail.svg);
        }
        .contact-item-text {
            margin-bottom: 10px;
        }
    }
}

/* Laptop */
@media (max-width: 1200px) {
    .contacts-page {
        .contacts-row {
            gap: 15px;
        }
    }
}

@media (max-width: 1050px) {
    .contacts-page {
        .contacts-row {
            flex-direction: column;
            gap: 32px;
            align-items: center;
        }
        .contact-row-desktop {
            display: none;
        }
        .contact-row-mobile {
            display: flex;
        }
        .contact-item {
            max-width: 400px;
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
    .contacts-page {
        .preview-section {
            margin-bottom: 0px;
        }
        .contact-row-mobile {
            margin-top: 80px;
        }
        .contact-item {
            max-width: 100%;
            height: auto;
        }
    }
}
