.single-article-page {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #777b83;
    .preview-section {
        padding-top: 0;
        .preview-bg {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .bg-filter {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: black;
                opacity: 0.3;
            }
        }
        .preview-header {
            width: 80%;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 800;
            font-size: 80px;
            line-height: 90px;
            letter-spacing: -0.06em;
        }
    }
    .article-content-section {
        margin-top: 50px;
        .article-header {
            font-family: "Roboto";
            font-weight: 800;
            font-size: 65px;
            line-height: 78px;
            letter-spacing: -0.06em;
            color: #121212;
            margin-bottom: 60px;
        }
        .article-tag {
            font-family: "Roboto";
            font-weight: 600;
            font-size: 12px;
            line-height: 30px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #ffffff;
            background-color: #f1275b;
            width: fit-content;
            padding: 0px 10px;
            margin-bottom: 45px;
        }
        .article-paragraph {
            margin-bottom: 30px;
            span {
                color: #121212;
                font-weight: 500;
            }
        }
        .medium-text {
            font-family: "Roboto";
            font-weight: 800;
            font-size: 21px;
            line-height: 30px;
            color: #121212;
            margin-bottom: 10px;
        }
        .img-wrapper {
            min-height: 300px;
            // max-height: 800px;
            overflow: hidden;
            margin-bottom: 20px;
            .article-img {
                width: 100%;
                object-fit: cover;
            }
        }
        .article-table-desctop {
            border: 1px solid black;
            max-width: 905px;
            margin-bottom: 20px;
            .article-table-row {
                border-bottom: 1px solid black;
                .article-table-item {
                    padding: 10px 20px;
                    border-right: 1px solid black;
                    width: 50%;
                }
                .article-table-item:last-child {
                    border-right: none;
                }
                .article-table-header {
                    font-weight: 700;
                    color: #121212;
                }
            }
        }
        .article-table-desctop-large {
            max-width: 800px;
            .article-table-row {
                .article-table-item {
                    width: auto;
                }
            }
        }
        .list {
            margin-left: 15px;
            > p {
                margin-bottom: 15px;
            }
        }
        .article-table-mobile {
            border: 1px solid black;
            max-width: 680px;
            display: none;
            .article-table-row {
                border-bottom: 1px solid black;
                .article-table-item {
                    padding: 10px 20px;
                }
            }
            .article-table-row:last-child {
                border-bottom: none;
            }
        }
        .border-left-paragraph {
            border-left: 3px solid #f1275b;
            padding-left: 15px;
            margin-bottom: 30px;
        }
        .subtitle {
            font-family: "Roboto";
            font-weight: 800;
            font-size: 28px;
            line-height: 30px;
            letter-spacing: -0.06em;
            color: #121212;
            margin-top: 50px;
            margin-bottom: 30px;
        }
        .article-paragraph-2 {
            margin-bottom: 30px;
        }
        .article-paragraph-3 {
            margin-bottom: 60px;
        }
        .images-wrapper {
            gap: 47px;
            margin-bottom: 60px;
            img {
                width: 100%;
                object-fit: contain;
            }
        }
        .recent-post-header {
            font-family: "Roboto";
            font-weight: 800;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.06em;
            color: #121212;
            min-width: 145px;
            background-color: #f9f9f9;
        }
        .tablet-header {
            display: none;
        }
        .tags {
            margin-bottom: 60px;
            overflow-x: auto;
            .header-and-tags-row {
                gap: 50px;
            }
            .tags-row {
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;
            }
            .tag-item {
                background: #f2f4f6;
                border-radius: 100px;
                padding: 5px 30px;
                font-family: "Roboto";
                font-weight: 600;
                font-size: 12px;
                line-height: 30px;
                text-transform: uppercase;
            }
            .active {
                background-color: #f1275b;
                color: white;
            }
        }
        .lines {
            border-top: 1px solid #eceff4;
            border-bottom: 1px solid #eceff4;
            height: 39px;
            margin-bottom: 20px;
        }
        .recent-post {
            .recent-post-header {
                margin-bottom: 40px;
            }
            .wrapper {
                display: grid;
                grid-template-columns: (repeat(3, 1fr));
                gap: 30px;
                margin-bottom: 100px;
            }
            .recent-post-item {
                a {
                    text-decoration: none;
                    color: #121212;
                }
                .article-tag {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    font-family: "Roboto";
                    font-weight: 700;
                    font-size: 11px;
                    line-height: 13px;
                    padding: 5px 13px;
                }
                .article-name {
                    font-family: "Roboto";
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 35px;
                    letter-spacing: -0.06em;
                }
            }
        }
    }
}

// Desctop
@media (max-width: 1200px) {
    .single-article-page {
        .article-content-section {
            .tablet-header {
                display: block;
                margin-bottom: 24px;
            }
            .desktop-header {
                display: none;
            }
            .tags-row {
                min-width: 1410px;
                margin-bottom: 20px;
            }
        }
    }
}

/* Tablet */
@media (max-width: 992px) {
    .single-article-page {
        .preview-section {
            .preview-header {
                font-size: 52px;
                line-height: 62px;
            }
        }
        .article-content-section {
            .recent-post {
                .wrapper {
                    grid-template-columns: 1fr;
                    justify-items: center;
                    .recent-post-item {
                        max-width: 500px;
                    }
                }
            }
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
    .single-article-page {
        .preview-section {
            .preview-header {
                font-size: 36px;
                line-height: 56px;
            }
        }
        .article-content-section {
            .article-header {
                font-size: 36px;
                line-height: 120%;
                margin-bottom: 32px;
            }
            .article-tag {
                margin-bottom: 32px;
            }
            .subtitle {
                margin-bottom: 32px;
            }
            .images-wrapper {
                flex-direction: column;
                gap: 32px;
            }
            .article-table-desctop-hide {
                display: none;
            }
            .article-table-desctop {
                .article-table-row {
                    .article-table-item {
                        padding: 3px 5px;
                    }
                }
            }
            .article-table-desctop-large {
                .article-table-row {
                    .article-table-item {
                        padding: 3px 5px;
                    }
                }
                .article-table-row:last-child {
                    border-bottom: none;
                }
            }
            .article-table-mobile {
                display: block;
            }
            .img-wrapper {
                min-height: auto;
            }
            .list {
                margin-left: 0px;
            }
            .recent-post {
                .wrapper {
                    .article-tag {
                        margin-top: 38px;
                        margin-bottom: 26px;
                    }
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .single-article-page {
        .preview-section {
            .preview-header {
                font-size: 26px;
                line-height: 40px;
            }
        }
    }
}
