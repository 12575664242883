a {
    text-decoration: none;
    color: #0c0c0c;
}
.article-preview {
    overflow: hidden;
    > img {
        object-fit: cover;
        width: 100%;
        height: 257px;
        transition: 0.3s all;
        &:hover {
            transform: scale(110%);
        }
    }
}
.item-tags-row {
    gap: 5px;
    margin-top: 38px;
    margin-bottom: 26px;
    flex-wrap: wrap;
}
.article-tag {
    color: white;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;

    padding: 5px 13px;
    background-color: #f1275b;
}
.article-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
}

/* Tablet */
@media (max-width: 992px) {
    .article-tag {
        margin-top: 36px;
        margin-bottom: 26px;
    }
    .item-tags-row {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
