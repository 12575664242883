.preview-section {
    padding-top: 40px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: calc(100vh - 122px);
    .bg-filter {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.3;
    }
    .preview-header {
        > p:nth-child(1) {
            font-family: "Roboto";
            font-weight: 800;
            font-size: 80px;
            line-height: 90px;
            letter-spacing: -0.06em;
            text-transform: uppercase;
            margin-bottom: 40px;
        }
        > p:nth-child(2) {
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            margin: 0 auto;
            width: 70%;
        }
        text-align: center;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .scroll-btn {
        position: absolute;
        bottom: 20px;
        right: 17px;
        transform: translateX(-50%);
        .scroll-btn-border {
            border-color: #e7e9ed;
        }
        .scroll-down-btn > p {
            color: #bcbfc3;
        }
    }
}
.add-margin {
    margin-bottom: 140px;
}
.ref {
    position: absolute;
    bottom: 244px;
}

@media (max-width: 1200px) {
    .preview-section {
        .preview-header {
            width: 100%;
        }
    }
}

// Mobile
@media (max-width: 576px) {
    .preview-section {
        .preview-header {
            padding: 0px 16px;
            > p:nth-child(1) {
                margin-bottom: 10px;
                line-height: 69px;
            }
            > p:nth-child(2) {
                width: 100%;
            }
        }
        .scroll-btn {
            bottom: -10px;
            right: 0px;
            left: 50%;
            transform: translateX(-50%);
            .scroll-down-btn {
                margin: 0 auto;
            }
        }
    }
}

@media (max-width: 480px) {
    .preview-section {
        .preview-header {
            > p:nth-child(1) {
                font-size: 60px;
            }
        }
    }
}

@media (max-width: 350px) {
    .preview-section {
        .preview-header {
            > p:nth-child(1) {
                font-size: 50px;
            }
        }
    }
}
