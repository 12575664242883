@import "../../style";

.services-section {
    position: relative;
    background: #f9f9f9;
    .services-header-row {
        gap: 55px;
        align-items: center;
    }
    .services-header {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 800;
        font-size: 60px;
        line-height: 120%;
        letter-spacing: -0.06em;
        text-transform: uppercase;
    }
    .info-btn {
        @include buttonWithArrow();
        border: 1px solid #f1275b;
        background-color: transparent;
        color: #f1275b;
        min-width: 151px;
        .btn-arrow {
            background-image: url(../../assets/arrow-right-red.svg);
        }
    }
    .services-links-row {
        padding-top: 40px;
        padding-bottom: 60px;
        justify-content: space-between;
        > a {
            color: #5b5b5b;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            text-transform: uppercase;
            transition: 0.3s all;
            &:hover {
                color: #f1275b;
            }
        }
    }
}

// Laptop
@media (max-width: 1200px) {
    .services-section {
        .links-wrapper {
            overflow-x: auto;
        }
        .services-links-row {
            min-width: 1130px;
        }
    }
}

/* Tablet */
@media (max-width: 992px) {
    .services-section {
        .services-header-row {
            flex-direction: column;
            align-items: start;
            gap: 30px;
        }
        .services-header {
            font-size: 46px;
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
    .services-section {
        .services-header-row {
            gap: 20px;
            > a {
                width: 100%;
            }
        }
        .services-header {
            font-size: 36px;
        }
        .info-btn {
            width: 100%;
            .btn-content-row {
                justify-content: center;
            }
        }
        .services-links-row {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}
