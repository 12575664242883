@use "reset.scss";

* {
    box-sizing: border-box;
}
.container {
    max-width: 1332px;
    margin: 0 auto;
    padding: 0px 32px;
}
.row {
    display: flex;
}

body {
    font-family: "Plus Jakarta Sans", sans-serif;
    background-color: #f9f9f9;
}

/* Mobile */
@media (max-width: 576px) {
    .container {
        padding: 0px 16px;
    }
}
