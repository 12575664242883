@import "../../style";

.introduce-section {
    position: relative;
    background-color: transparent;
    .bg-fixed {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-image: url(../../assets/introduce-section-bg.webp);
        background-repeat: no-repeat;
        background-size: cover;
    }
    .introduce-wrapper {
        position: relative;
        min-height: calc(100vh - 122px);
    }
    .introduce-text-wrapper {
        position: absolute;
        padding-bottom: 70px;
        top: 35%;
        transform: translateY(-35%);
    }
    .introduce-content {
        background-color: #c74869;
        color: white;
        max-width: 930px;
        padding: 32px;
        > p:nth-child(1) {
            font-family: "Roboto", sans-serif;
            font-weight: 800;
            font-size: 60px;
            line-height: 110%;
            text-transform: uppercase;
            letter-spacing: -0.06em;
            margin-bottom: 16px;
        }
        > p:nth-child(2) {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
        }
    }
    .scroll-btn-tablet {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: none;
    }
}

/* Tablet */
@media (max-width: 992px) {
    .introduce-section {
        .introduce-text-wrapper {
            position: static;
            width: 100%;
            transform: translateY(0%);
            .introduce-content {
                transform: translateY(50px);
                padding-right: 100px;
                > p:nth-child(1) {
                    font-size: 36px;
                }
            }
        }
        .scroll-btn-tablet {
            display: block;
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
    .introduce-section {
        .introduce-text-wrapper {
            .introduce-content {
                padding: 35px 32px;
            }
        }
        .scroll-btn-tablet {
            display: none;
        }
    }
}

@media (max-width: 390px) {
    .introduce-section {
        .introduce-text-wrapper {
            .introduce-content {
                > p:nth-child(1) {
                    font-size: 24px;
                }
            }
        }
    }
}
