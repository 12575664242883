@import "../../style";

.products-section {
    padding: 80px 0px;
    text-align: center;
    background: #f9f9f9;
    background-color: #f9f9f9;
    position: relative;
    .section-name {
        margin-bottom: 24px;
    }
    .products-header {
        margin-bottom: 24px;
    }
    .products-subtitle {
        margin-bottom: 60px;
        padding: 0px 200px;
    }
    .product-item {
        padding-right: 24px;
        min-width: 306px;
        .product-box {
            background-color: white;
            border: 1px solid #ededed;
            padding: 32px 16px;
            height: 286px;
            min-width: 306px;
            text-align: left;
            .long-header {
                font-size: 28px !important;
            }
            > p:nth-child(1) {
                font-family: "Roboto";
                font-weight: 800;
                font-size: 32px;
                line-height: 120%;
                letter-spacing: -0.06em;
                text-transform: uppercase;
                color: #121212;
                margin-bottom: 16px;
            }
            > p:nth-child(2) {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #595959;
                margin-bottom: 48px;
            }
        }
        .info-btn {
            @include buttonWithArrow();
            color: #121212;
            padding: 0px;
            background-color: transparent;
            .btn-arrow {
                background-image: url(../../assets/button-arr-red.svg);
            }
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
            }
        }
    }
    .products-btn {
        @include buttonWithArrow();
        color: #5b5b5b;
        background-color: transparent;
        border: 1px solid #5b5b5b;
        text-transform: uppercase;
        margin-top: 40px;
        .btn-arrow {
            background-image: url(../../assets/button-arr-gray.svg);
        }
    }
}

/* Tablet */
@media (max-width: 992px) {
    .products-section {
        padding: 50px 0px;
        .products-subtitle {
            margin-bottom: 30px;
            padding: 0px 70px;
        }
    }
}

/* for slider */
@media (max-width: 670px) {
    .products-section {
        .product-item {
            margin-left: 58px;
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
    .products-section {
        padding: 24px 0px;
        .products-subtitle {
            padding: 0px 0px;
        }
        .product-item {
            margin-left: 36px;
        }
        .products-btn {
            margin-top: 24px;
        }
    }
}

@media (max-width: 370px) {
    .products-section {
        .product-item {
            margin-left: 0px;
        }
    }
}
