.articles-page {
    .preview-section {
        background-image: url(../../../public/images/articles-bg.webp);
    }
    .articles-content-wrapper {
        margin-top: 50px;
    }
    .articles-header {
        flex-shrink: 0;
        margin-right: 26px;
        font-family: "Poppins";
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        background-color: #f9f9f9;
    }
    .mobile-header {
        display: none;
    }
    .desktop-header {
        display: block;
    }
    .tags-row {
        gap: 10px;
        margin-bottom: 50px;
        align-items: center;
        .tags-buttons {
            min-width: 906px;
            > button {
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }
        .tag-btn {
            padding: 2px 26px;
            background: #ffffff;
            border: 1px solid #f2f4f6;
            border-radius: 100px;
            color: rgba(18, 18, 18, 0.4);
            font-family: "Roboto";
            font-weight: 600;
            font-size: 12px;
            line-height: 30px;
            cursor: pointer;
            text-transform: uppercase;
        }
        .active {
            background-color: #f1275b;
            color: #ffffff;
        }
    }
    .wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px 24px;
    }
    .no-matches {
        text-align: center;
        font-family: "Poppins";
        font-weight: 600;
        font-size: 32px;
        line-height: 52px;
    }
    .article-item {
        a {
            text-decoration: none;
            color: #0c0c0c;
        }
    }
    .pages {
        margin-top: 40px;
        margin-bottom: 60px;
        text-align: center;
        position: relative;
        .anchor {
            position: absolute;
            top: -150px;
        }
        > div {
            gap: 7px;
            justify-content: center;
        }
        .prev-page {
            background-image: url(../../assets/long-arrow-left.svg);
        }
        .next-page {
            background-image: url(../../assets/long-arrow-right.svg);
        }
        .page {
            height: 49px;
            width: 49px;
            border: 1px solid #f2f4f6;
            background-color: #ffffff;
            color: #7a8085;
            background-position: center;
            background-repeat: no-repeat;
            font-family: "Jost";
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            cursor: pointer;
        }
        .active-page {
            background: #5b5b5b;
            color: white;
        }
        .hide {
            display: none;
        }
    }
    .message-section {
        background-position: 0% 0%;
    }
}

/* Laptop */
@media (max-width: 1130px) {
    .articles-page {
        .mobile-header {
            display: block;
            margin-bottom: 24px;
        }
        .desktop-header {
            display: none;
        }
        .tags-row {
            flex-direction: column;
            align-items: start;
            overflow-x: auto;
            gap: 22px;
            padding-bottom: 15px;
            .tags-buttons {
                min-width: 1360px;
            }
        }
    }
}

/* Tablet */
@media (max-width: 992px) {
    .articles-page {
        .wrapper {
            grid-template-columns: 1fr;
            gap: 24px;
        }

        .wrapper {
            grid-template-columns: 1fr 1fr;
            .article-item {
                .article-tag {
                    margin-top: 33px;
                    margin-bottom: 21px;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .articles-page {
        .wrapper {
            grid-template-columns: 1fr;
            gap: 24px;
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
}
