@import "../../../style";

.single-service-page {
    .preview-section {
        background-image: url(../../../../public/images/coal-supplies-page-bg.webp);
    }
    .content-wrapper {
        margin-top: 160px;
    }
    .links-row {
        display: grid;
        grid-template-columns: 25% 67%;
        align-items: flex-start;
    }
    .links-tablet-wrapper {
        overflow-x: auto;
        margin-bottom: 20px;
    }
    .links-tablet {
        display: none;
        gap: 24px;
        min-width: 1110px;
        padding: 15px 0px;
        > a {
            text-decoration: none;
            color: #121212;
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
            text-transform: uppercase;
        }
        .active {
            color: #c74869;
        }
    }
    .links-desktop {
        min-width: 300px;
        > a {
            text-decoration: none;
            color: #121212;
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
            text-transform: uppercase;
            cursor: pointer;
            > div {
                margin-bottom: 32px;
            }
        }
        > a:last-child {
            > div {
                margin-bottom: 0;
            }
        }
        .active {
            color: #c74869;
        }
    }
    .react-tabs__tab {
        margin-bottom: 32px;
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        cursor: pointer;
    }
    .react-tabs__tab--selected {
        color: #c74869;
        border: none;
        &:focus-visible {
            outline: none;
        }
    }
    .service-container {
        .service-content-header {
            font-family: "Roboto";
            font-weight: 800;
            font-size: 26px;
            line-height: 30px;
            margin-top: 40px;
            margin-bottom: 10px;
            text-transform: uppercase;
        }
        .service-content-header:nth-child(1) {
            margin-bottom: 33px;
            margin-top: 0;
        }
        .service-content-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;
            color: #7a8085;
            margin-bottom: 20px;
            span {
                color: #121212;
            }
        }
        .service-content-text:last-child {
            margin-bottom: 0;
        }
        .service-content-subtitle {
            font-family: "Roboto";
            font-weight: 800;
            font-size: 21px;
            line-height: 30px;
            color: #121212;
            margin-bottom: 20px;
        }
        .service-content-list {
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;
            color: #7a8085;
            margin-bottom: 20px;
            li {
                list-style: disc;
                margin-bottom: 10px;
            }
        }
        .service-content-numbered-list {
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;
            color: #7a8085;
            margin-bottom: 20px;
            li {
                list-style: decimal;
                margin-bottom: 10px;
                margin-left: 40px;
            }
        }
        .service-content-text-less-margin {
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;
            color: #7a8085;
            margin-bottom: 0px;
            span {
                color: #121212;
            }
        }
        .service-content-table {
            border: 1px solid black;
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;
            color: #7a8085;
            margin-bottom: 20px;
            .service-content-table-row {
                border-bottom: 1px solid black;
                .table-header {
                    font-weight: 700;
                    color: #121212;
                }
                .service-content-table-cell {
                    border-right: 1px solid black;
                    padding: 10px 15px;
                }
                .service-content-table-cell:first-child {
                    width: 50%;
                }
            }
        }
        .service-content-table-name {
            text-transform: uppercase;
        }
        .service-content-table-img {
            margin-top: 20px;
            margin-bottom: 40px;
        }
        .service-images-row {
            gap: 10px;
            .image-wrapper {
                width: 100%;
            }
            > img {
                width: 100%;
            }
        }
    }
    .download-button-wrapper {
        display: grid;
        grid-template-columns: 25% 75%;
    }
    .download-btn {
        padding: 16px 24px;
        border: none;
        color: white;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s all;
        margin-top: 20px;
        background-color: transparent;
        border: 1px solid #5b5b5b;
        text-transform: uppercase;
        .btn-content-row {
            gap: 8px;
            align-items: center;
        }
        .btn-arrow {
            transition: 0.3s all;
            height: 20px;
            width: 20px;
            background-image: url(../../../assets/button-arr-gray.svg);
            transform: rotate(90deg);
            background-repeat: no-repeat;
            background-position: center;
        }
        p {
            font-family: "Plus Jakarta Sans";
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
        }
        a {
            text-decoration: none;
            color: #5b5b5b;
        }
    }
    .margin-bottom {
        height: 120px;
    }
    .service-content-img {
        width: 100%;
        height: 315px;
        object-fit: cover;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .service-content-table {
        width: 100%;
    }
}

.coal-supplies-page {
    .service-container {
        .service-content-table {
            .service-content-table-row {
                .service-content-table-cell:first-child {
                    width: auto;
                }
            }
        }
    }
}

@media (hover: hover) {
    .single-service-page {
        .download-btn {
            &:hover {
                background-color: #bebebe;
            }
        }
    }
}

/* Tablet */
@media (max-width: 992px) {
    .single-service-page {
        .content-wrapper {
            margin-top: 80px;
        }
        .links-tablet {
            display: flex;
        }
        .links-desktop {
            display: none;
        }
        .links-row {
            display: block;
        }
        .service-container {
            margin: 0px 0px;
        }
        .download-button-wrapper {
            display: grid;
            grid-template-columns: 0% 100%;
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
    .single-service-page {
        .service-container {
            margin: 0px;
            .service-content-table {
                .service-content-table-row {
                    .service-content-table-cell:first-child {
                        width: auto;
                    }
                }
            }
        }
        .download-btn {
            width: 100%;
            .btn-content-row {
                justify-content: center;
            }
        }
    }
}
