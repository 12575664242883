.press-article-item {
    max-width: 307px;
    margin-right: 24px;
    .press-article-img {
        overflow: hidden;

        > img {
            transition: 0.3s all;
            &:hover {
                transform: scale(110%);
            }
        }
    }
    .press-article-title {
        font-family: "Roboto", sans-serif;
        font-size: 26px;
        font-weight: 800;
        line-height: 31px;
        letter-spacing: -0.06em;
        padding: 24px 0px;
    }
    .press-article-subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
    }
}
