@import "../../style";

.message-section {
    background-image: url(../../../public/images/message-bg.webp);
    background-repeat: no-repeat;
    background-position: 0% -27%;
    background-size: cover;
    position: relative;
    .close-btn {
        display: none;
        width: 24px;
        height: 24px;
        background-image: url(../../assets/close-burger-img.svg);
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        transition: 0.3s all;
        &:hover {
            transform: scale(130%);
        }
    }
    .message-header {
        font-family: "Anton";
        font-weight: 400;
        font-size: 42px;
        line-height: 60px;
        color: #121212;
        text-align: center;
        margin-bottom: 50px;
    }
    .message-form {
        text-align: center;
        margin: 0 auto;
        max-width: 1082px;
        .input-row {
            justify-content: space-between;
            gap: 27px;
            margin-bottom: 27px;
            .input-item-row {
                width: 100%;
                > input:nth-child(1) {
                    margin-bottom: 27px;
                }
            }
        }
        .message-input {
            height: 62px;
            width: 100%;
            background: #fbfbfb;
            border: 1px solid #f2f4f6;
            border-radius: 5px;
            padding: 19px 18px;
            font-weight: 400;
            font-size: 15px;
            line-height: 25px;
            color: #777b83;
            &::placeholder {
                color: #777b83;
                font-family: "Plus Jakarta Sans";
                font-weight: 400;
                font-size: 15px;
                line-height: 25px;
            }
        }
        .message-textarea {
            width: 100%;
            height: 225px;
            padding: 20px 18px;
            background: #fbfbfb;
            border: 1px solid #f2f4f6;
            border-radius: 5px;
            resize: none;
            color: #777b83;
            font-weight: 400;
            font-size: 15px;
            line-height: 25px;
            margin-bottom: 31px;
            &::placeholder {
                color: #777b83;
                font-family: "Plus Jakarta Sans";
                font-weight: 400;
                font-size: 15px;
                line-height: 25px;
            }
        }
        .message-btn {
            @include buttonWithArrow();
            background: #f1275b;
            text-transform: uppercase;
            margin-bottom: 158px;
        }
    }
}

/* Tablet */
@media (max-width: 992px) {
    .message-section {
        .message-header {
            margin-bottom: 32px;
        }
        .message-form {
            padding-left: 16px;
            padding-right: 16px;
            .input-row {
                flex-direction: column;
                gap: 32px;
                margin-bottom: 32px;
                .input-item-row {
                    > input:nth-child(1) {
                        margin-bottom: 32px;
                    }
                }
            }
            .message-btn {
                margin-bottom: 80px;
            }
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
}
