@import "../../style";

.quotation {
    background-color: #121212;
    height: 45px;
    overflow: hidden;
}
.burger-menu {
    position: fixed;
    right: 0px;
    height: calc(100vh - 45px);
    width: 90%;
    background-color: white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.95);
    padding: 32px;
    transition: 0.5s all;
    z-index: 100;
    overflow-y: auto;
    .close-burger-img {
        width: 24px;
        height: 24px;
        background-image: url(../../assets/close-burger-img.svg);
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        right: 22px;
        top: 22px;
    }
    .burger-menu-items {
        margin-top: 130px;
        margin-bottom: 140px;
    }
    a {
        display: block;
        text-decoration: none;
        color: #121212;
        font-weight: 400;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 32px;
        margin-left: 18px;
    }
    .active {
        font-weight: 700;
    }
    .submit-btn {
        @include buttonWithArrow();
        background-color: #f1275b;
        width: 100%;
        margin-bottom: 16px;
        .btn-content-row {
            justify-content: center;
        }
    }
    .call-btn {
        @include buttonWithArrow();
        background-color: #5b5b5b;
        width: 100%;
        .btn-content-row {
            justify-content: center;
        }
    }
}
.show-burger {
    right: 0px;
}
.hide-burger {
    right: -110%;
}
.header {
    background-color: white;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 100;
    .hide {
        display: none;
    }
    .show {
        display: block;
    }
    .logo-and-links {
        align-items: center;
        gap: 120px;
    }
    .header-logo {
        width: 175px;
        height: 50px;
    }
    .quotation {
        color: white;
        .quotation-item {
            margin-right: 15px;
        }
    }
    .subscribe-bg {
        z-index: 0;
        height: 100vh;
        width: 100%;
        background-color: #00000088;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .subscribe-section {
        z-index: 10;
        position: fixed;
        width: 600px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 40px 43px;
        .subscribe-row {
            flex-direction: column;
        }
        .subscribe-header {
            width: 84%;
        }
        .close-popup-btn {
            cursor: pointer;
        }
    }
    .callback-section {
        z-index: 10;
        position: fixed;
        width: 600px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 40px 43px;
        background-color: #c74869;
        color: white;
        .close-popup-btn {
            cursor: pointer;
            transition: 0.3s all;
            &:hover {
                transform: scale(130%);
            }
        }
        .callback-row {
            flex-direction: column;
        }
        .callback-header {
            width: 84%;
            font-family: "Anton";
            font-weight: 400;
            font-size: 32px;
            line-height: 42px;
            text-transform: uppercase;
            margin-bottom: 32px;
        }
        .input-phone-row {
            margin-bottom: 16px;
            gap: 25px;
            .number-input {
                height: 48px;
                width: 292px;
                padding: 12px;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #505050;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                &::placeholder {
                    font-family: "Plus Jakarta Sans";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #505050;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                }
            }
            .number-btn {
                height: 48px;
                width: 195px;
                background-color: #121212;
                color: white;
                font-family: "Plus Jakarta Sans";
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                border: none;
                transition: 0.3s all;
                cursor: pointer;
                &:hover {
                    background: #2b2b2b;
                }
            }
        }
    }
    .close-popup-btn {
        display: block;
        background-image: url(../../assets/close-button-img.svg);
        background-position: center;
        background-repeat: no-repeat;
        z-index: 10;
        position: absolute;
        width: 24px;
        height: 24px;
        top: 16px;
        right: 20px;
    }
}

.header-row {
    align-items: center;
    justify-content: space-between;
    padding: 11px 0px 14px 0px;
}
.header-menu {
    margin-left: 43px;
    gap: 32px;
    > a {
        text-decoration: none;
        color: #121212;
        font-weight: 400;
        text-transform: uppercase;
        transition: 0.3s all;
        &:hover {
            color: #f1275b;
        }
    }
    .active {
        font-weight: 700;
    }
}
.header-btns {
    > button {
        @include buttonWithArrow();
    }
    .submit-btn {
        background-color: #f1275b;
        margin-right: 16px;
    }
    .call-btn {
        background-color: #5b5b5b;
    }
}
.burger-menu-btn {
    background-image: url(../../assets/burger-menu-img.svg);
    width: 24px;
    height: 24px;
    display: none;
}
.header-overlay {
    height: 122px;
    width: 100%;
}

@media (max-width: 1235px) {
    .header {
        .logo-and-links {
            gap: 0px;
        }
    }
}

/* Laptop */
@media (max-width: 1200px) {
    .header {
        .logo-and-links {
            gap: 0px;
        }
        .header-menu {
            margin-left: 20px;
        }
    }
}

@media (max-width: 1100px) {
    .header-btns {
        display: none;
    }
}

/* Tablet */
@media (max-width: 992px) {
    .header-menu {
        gap: 21px;
    }
    .header {
        .subscribe-section {
            width: 320px;
            padding: 56px 0px;
            .subscribe-header {
                width: 100%;
            }
            .subscribe-row {
                gap: 24px;
            }
            .input-email-row {
                flex-direction: column;
                .email-input {
                    width: 100%;
                    margin-right: 0;
                }
                .email-btn {
                    width: 100%;
                    margin-top: -20px;
                }
                .input-annotation {
                    transform: translateY(300%);
                    text-align: center;
                }
            }
        }
        .callback-section {
            width: 320px;
            padding: 56px 0px;
            .callback-header {
                width: 100%;
            }
            .input-phone-row {
                flex-direction: column;
                .number-input {
                    width: 100%;
                }
                .number-btn {
                    width: 100%;
                }
            }
            .input-annotation {
                text-align: center;
            }
        }
    }
}

@media (max-width: 730px) {
    .header-menu {
        gap: 5px;
    }
}

@media (max-width: 670px) {
    .header-menu {
        display: none;
    }
    .burger-menu-btn {
        display: block;
    }
}

/* Trading adaptive */
@media (max-width: 767px) {
    .header {
        .quotation {
            height: 75px;
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
    .header {
        .subscribe-section {
            .subscribe-header {
                font-family: "Anton";
                font-weight: 400;
                font-size: 32px;
            }
            .input-email-row {
                .email-btn {
                    margin-top: -25px;
                }
                .input-annotation {
                    transform: translateY(350%);
                }
            }
        }
    }
}
