.scroll-down-btn {
    max-width: 185px;
    text-align: center;
    cursor: pointer;
    .scroll-btn-border {
        margin: 0 auto;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: 1px solid white;
        position: relative;
        margin-bottom: 25px;
        filter: drop-shadow(0px 13px 32px rgba(199, 199, 199, 0.25));
    }
    .scroll-btn-arr {
        width: 96px;
        height: 96px;
        background-color: white;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url(../../assets/scroll-down-arr.svg);
        background-position: center;
        background-repeat: no-repeat;
        transition: 0.3s all;
        filter: drop-shadow(0px 13px 32px rgba(199, 199, 199, 0.25));
        &:active {
            width: 120px;
            height: 120px;
            background-color: #bcbfc3;
        }
    }
    > p {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: #bcbfc3;
    }
}

@media (hover: hover) {
    .scroll-btn-arr {
        &:hover {
            width: 120px;
            height: 120px;
        }
    }
}

// Mobile
@media (max-width: 576px) {
    .scroll-down-btn {
        > p {
            display: none;
        }
    }
}
