.subscribe-section {
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: #c74869;
    color: white;
    position: relative;
    .close-popup-btn {
        display: none;
        transition: 0.3s all;
        &:hover {
            transform: scale(130%);
        }
    }
    .subscribe-row {
        justify-content: space-between;
        align-items: center;
        gap: 32px;
    }
    .subscribe-header {
        font-family: "Anton";
        font-weight: 400;
        font-size: 32px;
        line-height: 120%;
        text-transform: uppercase;
        width: 50%;
    }
    .email-input {
        height: 48px;
        width: 290px;
        margin-right: 25px;
        padding: 12px;
        font-family: "Plus Jakarta Sans";
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #505050;
        border: 1px solid white;
        &::placeholder {
            font-family: "Plus Jakarta Sans";
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #505050;
        }
    }
    .email-btn {
        width: 195px;
        height: 48px;
        background: #121212;
        border: 1px solid #121212;
        color: white;
        cursor: pointer;
        transition: 0.3s all;
        &:hover {
            background: #2b2b2b;
        }
    }
    .input-annotation {
        margin-top: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
    }
}

// Tablet
@media (max-width: 992px) {
    .subscribe-section {
        .subscribe-row {
            flex-direction: column;
        }
        .subscribe-header {
            width: 100%;
        }
        .input-email-row {
            justify-content: center;
        }
    }
}

// Mobile
@media (max-width: 576px) {
    .subscribe-section {
        padding: 35px 0px;
        .subscribe-row {
            gap: 16px;
        }
        .subscribe-header {
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;
        }
        .input-email-row {
            flex-direction: column;
            input {
                width: 100%;
                margin-right: 0;
            }
            .input-annotation {
                margin-top: 8px;
                margin-bottom: 16px;
            }
            button {
                width: 100%;
            }
        }
    }
}
