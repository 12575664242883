@mixin buttonWithArrow() {
    padding: 16px 24px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s all;
    &:hover .btn-arrow {
        transform: translateX(35%);
    }
    .btn-content-row {
        gap: 8px;
        align-items: center;
    }
    .btn-arrow {
        transition: 0.3s all;
        height: 20px;
        width: 20px;
        background-image: url(./assets/button-arr.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
    p {
        font-family: "Plus Jakarta Sans";
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
    }
}
