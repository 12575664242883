@import "../../style";

.articles-section {
    padding: 80px 0px;
    position: relative;
    background: #f9f9f9;
    .small-header {
        margin-bottom: 24px;
    }
    .articles-row {
        justify-content: space-between;
        margin-bottom: 24px;
        gap: 10px;
        .more-articles-btn {
            min-width: 205px;
        }
    }
    .articles-btn {
        @include buttonWithArrow();
        color: #5b5b5b;
        background-color: transparent;
        border: 1px solid #5b5b5b;
        text-transform: uppercase;
        .btn-arrow {
            background-image: url(../../assets/button-arr-gray.svg);
        }
    }
    .articles-items-row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        .article-item {
            .article-item-img {
                overflow: hidden;
                margin-bottom: 24px;
                height: 320px;
            }
            img {
                width: 100%;
                height: 320px;
                object-fit: cover;
                transition: 0.3s all;
                &:hover {
                    transform: scale(110%);
                }
            }
            > a {
                font-family: "Roboto";
                font-weight: 800;
                font-size: 23px;
                line-height: 120%;
                letter-spacing: -0.06em;
                text-transform: uppercase;
                color: #121212;
                text-decoration: none;
            }
            .article-text {
                font-family: "Plus Jakarta Sans";
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: normal;
                margin-top: 15px;
                text-transform: none;
            }
        }
    }
    .articles-text {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #595959;
        margin-bottom: 24px;
    }
}

@media (max-width: 1350px) {
    .articles-section {
        .articles-items-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .article-item {
                text-align: center;
                > p {
                    margin: 0px 70px;
                }
            }
        }
    }
}

/* Tablet */
@media (max-width: 992px) {
    .articles-section {
        .articles-items-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .article-item {
                text-align: center;
                > p {
                    margin: 0px 40px;
                }
            }
        }
    }
}

@media (max-width: 680px) {
    .articles-section {
        .more-articles-btn {
            display: none;
        }
        .articles-row {
            .articles-header {
                width: 100%;
            }
        }
        .articles-items-row {
            display: block;
            .article-item:nth-child(2) {
                display: none;
            }
            .article-item:nth-child(3) {
                display: none;
            }
            .article-item:nth-child(4) {
                display: none;
            }
            .article-item {
                > img {
                    width: 70%;
                }
            }
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
    .articles-section {
        padding: 60px 0px;
        .articles-row {
            margin-bottom: 16px;
        }
        .articles-items-row {
            .article-item {
                > img {
                    width: 100%;
                    margin-bottom: 16px;
                }
                > p {
                    margin: 0;
                    font-size: 18px;
                    line-height: 120%;
                }
            }
        }
    }
}
