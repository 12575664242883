.about-section {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
    background: #f9f9f9;
    background-image: url(../../../public/images/message-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% -25%;
    position: relative;

    .small-header {
        margin-bottom: 24px;
    }
    .big-header {
        margin-bottom: 24px;
    }
    .subtitle {
        margin: 0px 325px 66px;
    }
    .about-text-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #121212;
        text-align: left;
        gap: 23px;
        margin: 0px 120px;
        .about-text-paragraph {
            margin-bottom: 30px;
        }
    }
}

/* Desktop */
@media (max-width: 1200px) {
    .about-section {
        .subtitle {
            margin: 0px 200px 66px;
        }
    }
}

/* Tablet */
@media (max-width: 992px) {
    .about-section {
        .subtitle {
            margin: 0px 0px 32px;
        }
        .about-text-wrapper {
            grid-template-columns: 1fr;
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
    .about-section {
        background-image: none;
        padding-bottom: 60px;
        .about-text-wrapper {
            grid-template-columns: 1fr;
        }
        .about-text-wrapper {
            margin: 0px;
        }
    }
}
