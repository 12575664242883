@import "../../style";

.footer {
    background: #1e222d;
    color: white;
    padding-top: 81px;
    padding-bottom: 35px;
    position: relative;
    .hide {
        display: none;
    }
    .show {
        display: block;
    }
    .subscribe-bg {
        height: 100vh;
        width: 100%;
        background-color: #00000088;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .message-section {
        padding: 20px;
        z-index: 5000;
        position: fixed;
        background-color: #f9f9f9;
        width: 50%;
        top: 63%;
        left: 50%;
        max-height: 70vh;
        overflow-y: auto;
        transform: translate(-50%, -60%);
        .close-btn {
            display: block;
        }
        .message-form {
            .message-btn {
                margin-bottom: 40px;
            }
        }
    }
    .footer-row {
        gap: 40px;
    }
    .footer-item-header {
        width: 45%;
        .big-header {
            margin-bottom: 32px;
        }
    }
    .footer-links-row {
        display: grid;
        grid-template-columns: 130px 150px 250px 200px;
        gap: 30px;
    }
    .footer-item-text {
        width: 100%;
        .footer-column-header {
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            margin-bottom: 16px;
        }
        .footer-column-item {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 16px;
            > a {
                display: block;
                text-decoration: none;
                color: white;
                margin-bottom: 16px;
                transition: 0.3s all;
                &:hover {
                    color: #f1275b;
                }
            }
            // .submit-btn {
            //     @include buttonWithArrow();
            //     background-color: transparent;
            //     border: 1px solid #ffffff;
            //     margin-top: 18px;
            //     text-transform: uppercase;
            //     padding: 19px 24px;
            // }
            .press-links-title {
                font-size: 18px;
                font-weight: 700;
                line-height: 27px;
                letter-spacing: 0em;
                color: #f1275b;
                text-transform: uppercase;
            }
            .press-link {
                height: 23px;
                background-position: left;
                background-size: contain;
                background-repeat: no-repeat;
                margin-top: 20px;
                margin-bottom: 20px;
                transition: 0.3s all;
            }
            .press-link-1 {
                background-image: url(../../assets/press-link-img-1.svg);
                &:hover {
                    background-image: url(../../assets/press-link-img-hovered-1.svg);
                }
            }
            .press-link-2 {
                background-image: url(../../assets/press-link-img-2.svg);
                background-size: 45%;
                &:hover {
                    background-image: url(../../assets/press-link-img-hovered-2.svg);
                }
            }
            .press-link-3 {
                background-image: url(../../assets/press-link-img-3.svg);
                &:hover {
                    background-image: url(../../assets/press-link-img-hovered-3.svg);
                }
            }
            .press-link-4 {
                background-image: url(../../assets/press-link-img-4.svg);
                &:hover {
                    background-image: url(../../assets/press-link-img-hovered-4.svg);
                }
            }
        }
        .footer-contacts-item-1 {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            margin-bottom: 16px;
        }
        .adress-wrapper {
            margin-bottom: 16px;
            gap: 10px;
            .flag {
                min-width: 12px;
                height: 12px;
                line-height: 150%;
                margin-top: 3px;
            }
            .us-flag {
                background-image: url(../../assets/us-flag.svg);
            }
            .uae-flag {
                background-image: url(../../assets/uae-flag.svg);
            }
        }
        .footer-contacts-item-2 {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            .bold-text {
                font-weight: 700;
            }
        }
    }
    .big-header {
        color: white;
    }
    .footer-btn {
        @include buttonWithArrow();
        background: #f1275b;
    }
    .copyright {
        text-align: center;
        margin-top: 73px;
    }
}

// Desktop
@media (max-width: 1270px) {
    .footer {
        .footer-row {
            justify-content: center;
        }
        .footer-links-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .footer-item-text {
            .footer-column-item {
                display: flex;
                flex-direction: column;
                .press-links-title {
                    margin-top: 30px;
                }
                .press-link {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

// Tablet
@media (max-width: 992px) {
    .footer {
        .footer-row {
            flex-direction: column;
        }
        .message-section {
            padding: 54px 24px;
            width: 80%;
            height: 90%;
            overflow-y: auto;
            top: 62%;
            transform: translate(-50%, -50%);
            .message-form {
                .input-row {
                    gap: 12px;
                    margin-bottom: 12px;
                    .input-item-row {
                        > input:nth-child(1) {
                            margin-bottom: 12px;
                        }
                    }
                }
            }
        }
        .footer-item-text {
            max-width: 180px;
            .adress-wrapper {
                max-width: 300px;
            }
        }
        .footer-item-text:nth-child(3) {
            max-width: 250px;
        }
    }
}

// Mobile
@media (max-width: 576px) {
    .footer {
        padding-top: 35px;
        .footer-item-header {
            width: 100%;
            .big-header {
                margin-bottom: 16px;
            }
        }
        .footer-row {
            gap: 50px;
        }
        .footer-btn {
            width: 100%;
            .btn-content-row {
                justify-content: center;
            }
        }
        .footer-links-row {
            grid-template-areas: "a b" "c d";
            gap: 30px 20px;
            .footer-item-text:nth-child(1) {
                grid-area: a;
            }
            .footer-item-text:nth-child(2) {
                grid-area: b;
            }
            .footer-item-text:nth-child(3) {
                grid-area: c;
            }
            .footer-item-text:nth-child(4) {
                grid-area: d;
            }
        }
        .footer-item-text {
            min-width: auto;
            max-width: 100%;
        }
        .message-section {
            padding: 32px 5px;
            width: 80%;
            height: 80%;
            top: 60%;
            .message-header {
                padding: 0px 15px;
            }
        }
    }
}
