.main {
    background-color: #f9f9f9;
    .scroll-btn-fixed {
        position: fixed;
        right: 95px;
        bottom: 20px;
        z-index: 3000;
        > p {
            color: #bcbfc3;
        }
    }
    .show {
        opacity: 1;
        z-index: 1000;
        transition: 0.3s all;
    }
    .hide {
        opacity: 0;
        z-index: -1;
        transition: 0.3s all;
    }
    .bg-color {
        padding-top: 0;
    }
    .section-anker-element {
        width: 10px;
        height: 10px;
        position: absolute;
        top: -122px;
        z-index: 5000;
    }
    .trading-widget-wrapper {
        position: relative;
        background-color: #f9f9f9;
        padding-top: 80px;
        .tradingview-widget-container {
            width: 100% !important;
            iframe {
                width: 100% !important;
            }
        }
    }
    .tv-widget-chart__chart-wrap {
        display: none;
    }
}

.small-header {
    font-family: "Plus Jakarta Sans";
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #f1275b;
}
.big-header {
    font-family: "Roboto";
    font-weight: 800;
    font-size: 60px;
    line-height: 120%;
    letter-spacing: -0.06em;
    text-transform: uppercase;
    color: #121212;
}
.subtitle {
    font-family: "Plus Jakarta Sans";
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #595959;
}

/* Tablet */
@media (max-width: 992px) {
    .big-header {
        font-size: 46px;
    }
    .main {
        .scroll-btn-fixed {
            display: none;
        }
    }
}

/* Mobile */
@media (max-width: 576px) {
    .big-header {
        font-size: 36px;
    }
}
